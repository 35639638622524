import { AxiosInstance } from "axios";
import { Service } from "../../service";
import { PaginationData } from "./../../../components/pagination/types";

export class ComentarioPublicoService extends Service {

  private ENDPOINT = "public-comment/moderation/";

  async listarComentariosPorPagina(props: {
    page: number,
    qtd: string,
    period: string,
    filters: string[],
    search: string,
    sort: string,
    order: string,
    dataPublicacao: string
  }
  ): Promise<ComentarioItemPagination> {
    return this.fetch
      .get(this.ENDPOINT +
        `?search=search_key:'*${props.search}*';period:'${props.period}';publicationDate:'${props.dataPublicacao}';nickname:'${props.filters[0]}'&page=${props.page}&size=${props.qtd}&sort=${props.sort}&order=${props.order}`
      )
      .then((response) => {

        let questoes = new Array<ComentarioPublicoDetails>();
        let pagination = {
          current_page: 0,
          total_items: 0,
          total_number_pages: 1,
        };

        questoes = response.data.content;
        pagination = {
          total_items: response.data.total_items,
          current_page: response.data.current_page,
          total_number_pages: response.data.total_number_pages,
        };

        return {
          content: questoes,
          paginationData: pagination,
        };
      });
  }

  async editarComentario(
    idComentario: number,
    texto: string
  ): Promise<void> {

    const data = {
      content: texto
    }

    return this.fetch.put(
      this.ENDPOINT + `${idComentario}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async excluirComentario(idComentario: number): Promise<void> {
    return this.fetch.delete(this.ENDPOINT + `${idComentario}`);
  }

}

export const useComentarioPublicoService = (axios: AxiosInstance) => {
  return new ComentarioPublicoService(axios);
};

export interface ComentarioPublicoDetails {
  public_comment_id: number;
  question_id: number;
  author_nickname: string;
  content: string;
  updater: string;
  publication_date: string;
  update_date: string;
  dislikes: string;
  likes: string;
}

export type ComentarioItemPagination = {
  content: Array<ComentarioPublicoDetails>;
  paginationData: PaginationData;
};
