import { BiCommentEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { ThreeNormalOption } from "../../../../components/menu/three-normal";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { CardItem } from "../../../../components/page/structure/ListComponent/list-card/model";
import {
  ItemDataSelect,
  SelectedDataActions,
} from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ComentarioPublicoDetails } from "../../../../services/api-services/comentariosPublicos";
import { ComentarioPublicoCardBody } from "./body";
import { ComentarioPublicoCardFooter } from "./footer";
import { ComentarioPublicoCardHeader } from "./header";
import { ComentarioPublicoCardComponenteStyles } from "./styles";

export interface ComentarioPublicoCardComponentProps extends ComentarioPublicoCardProps {
  elem: ItemDataSelect<ComentarioPublicoDetails>;
  index: number;
}

export interface ComentarioPublicoCardProps {
  selectedEntitiesAction: SelectedDataActions<ComentarioPublicoDetails>;
  mode: string;
}

export const ComentarioPublicoCardComponente = ({
  elem,
  index,
  mode,
  selectedEntitiesAction,
}: ComentarioPublicoCardComponentProps) => {

  const comentarioPublico = elem.entity as ComentarioPublicoDetails;
  const context = useGerenciarContext();

  const renderBody = () => {
    return <ComentarioPublicoCardBody comentario={comentarioPublico} />;
  };

  return (
    <ComentarioPublicoCardComponenteStyles>
      <CardItem
        settings={{
          width: "100%",
        }}
        header={
          <ComentarioPublicoCardHeader
            elem={elem}
            index={index}
            selectedEntitiesAction={selectedEntitiesAction}
            mode={mode}
          />
        }
        body={renderBody()}
        footer={
          <ComentarioPublicoCardFooter
            comentario={comentarioPublico}
          />
        }
        options={
          [
            <ThreeNormalOption
              name="Excluir"
              icon={MdDelete}
              onClick={() => {
                context.changeAction({
                  action: "excluir",
                  id: elem.entity.public_comment_id,
                });
              }}
            />,
            <ThreeNormalOption
              name="Reeditar comentário"
              icon={BiCommentEdit}
              onClick={() => {
                context.changeAction({
                  action: "reeditar-comentario",
                  id: comentarioPublico.public_comment_id,
                  object: comentarioPublico
                });
              }}
            />,
          ]
        }
      />
    </ComentarioPublicoCardComponenteStyles>
  );
};
