import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { useDebounce as debounce } from "use-debounce";
import { Input } from "./styles";

interface TInputNormal extends InputHTMLAttributes<HTMLInputElement> {
  onChangeValue(value: string): void;
  initialValue?: string;
}

export const InputNormal: React.FC<TInputNormal> = ({
  onChangeValue,
  initialValue,
  ...rest
}) => {
  const [value, setValue] = useState("");
  const [debouncedText] = debounce(value, 500);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    onChangeValue(debouncedText);
  }, [debouncedText]);

  return (
    <Input
      type={"text"}
      {...rest}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};
