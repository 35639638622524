import { InputAreaForm } from "jusadmin-ui-kit";
import React, { useEffect, useState } from "react";
import { VscSave } from "react-icons/vsc";
import { ButtonNormal } from "../../../../components/button/normal";
import { Modal } from "../../../../components/modals/modal-base";
import { useGerenciarContext } from "../../../../components/page/GerenciarPageBase/context";
import { ActionType } from "../../../../components/page/GerenciarPageBase/types";
import { APIFetch } from "../../../../services/api";
import { ComentarioPublicoDetails, useComentarioPublicoService } from "../../../../services/api-services/comentariosPublicos";
import { useAuthContext } from "../../../../services/auth-services/auth/contextAuth";
import { ComentarioPublicoContent, ReeditarComentarioPublicoContent } from "./styles";

export interface EditarComentarioPublicoProps {
  externalAction?: [
    ActionType,
    React.Dispatch<React.SetStateAction<ActionType>>
  ];
}

export const EditarComentarioPublico: React.FC<EditarComentarioPublicoProps> = ({ externalAction }) => {
  const auth = useAuthContext();

  const { changeAction, currentActionState } = useGerenciarContext();

  const actionState = externalAction ? externalAction : currentActionState;

  const [currentAction, setCurrentAction] = actionState;

  const services = {
    comentarioPublicoService: useComentarioPublicoService(APIFetch(auth))
  };

  const [comentarioPublico, setComentarioPublico] = useState("");

  const onSubmit = () => {
    services.comentarioPublicoService
      .editarComentario(currentAction.id, comentarioPublico)
      .then((response) => {
        changeAction({
          ...currentAction,
          loading: true,
        });
        alert("Comentário atualizado com sucesso!");
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  useEffect(() => {

    if (currentAction.action === "reeditar-comentario") {

      const comentario = currentAction.object as ComentarioPublicoDetails;

      if (comentario) {
        setComentarioPublico(comentario.content);
      }

    }

  }, []);

  const footer = () => {
    return (
      <ButtonNormal
        icon={VscSave}
        onClick={() => onSubmit()}
        title={"Salvar alterações"}
        className={"save"}
        type={"button"}
      />
    );
  };

  return (
    <Modal
      width={900}
      action={actionState}
      options={footer()}
      title={`Reeditar comentário público #${currentAction.id}`}
    >
      <ReeditarComentarioPublicoContent>
        <ComentarioPublicoContent>
          <div className="form-group">
            <div style={{
              width: "100%"
            }} className="field-group">
              <label>Conteúdo do comentário:</label>
              <InputAreaForm
                className="comentario-conteudo"
                value={comentarioPublico}
                placeholder={"Comentário público"}
                onChange={(event) => {
                  setComentarioPublico(event.currentTarget.value);
                }}
              />
            </div>
          </div>
        </ComentarioPublicoContent>
      </ReeditarComentarioPublicoContent>
    </Modal>
  );
};
