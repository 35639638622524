import { ComentarioPublicoCardComponente, ComentarioPublicoCardProps } from ".";
import { ItemDataSelect } from "../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { ComentarioPublicoDetails } from "../../../../services/api-services/comentariosPublicos";
import { CriarQuestaoProvider } from "../../../CriarQuestao/provider";
export const ComentarioPublicoCardRender = ({
    mode,
    selectedEntitiesAction,
}: ComentarioPublicoCardProps) => {

    const Component = (elem: ItemDataSelect<ComentarioPublicoDetails>, index: number) => {
        return (
            <CriarQuestaoProvider desativarAtualizacao={false}>
                <ComentarioPublicoCardComponente
                    elem={elem}
                    index={index}
                    key={elem.id_item}
                    selectedEntitiesAction={selectedEntitiesAction}
                    mode={mode}
                />
            </CriarQuestaoProvider>
        );
    };

    return {
        component: Component,
    };
};
