import { ComentarioPublicoDetails } from "../../../../../services/api-services/comentariosPublicos";
import { BodyStyle } from "../styles";

export interface ComentarioPublicoCardBodyProps {
    comentario: ComentarioPublicoDetails
}

export const ComentarioPublicoCardBody = ({ comentario }: ComentarioPublicoCardBodyProps) => {
    return (
        <BodyStyle className={"item-margin"}>
            {comentario.update_date !== null && <span className={"data-atualizacao"}>{`Reeditado em: ${comentario.update_date} `}</span>}
            <pre className="public_content" style={{
                whiteSpace: "pre-wrap",
                fontSize: "14px"
            }}>
                {comentario.content}
            </pre>
            <div className="tags">

            </div>
        </BodyStyle>
    );
};
