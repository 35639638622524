import { CheckBox } from "../../../../../components/checkbox";
import {
    ItemDataSelect,
    SelectedDataActions,
} from "../../../../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import { Tag } from "../../../../../components/tag";
import { ComentarioPublicoDetails } from "../../../../../services/api-services/comentariosPublicos";
import { HeaderStyle } from "../styles";

export interface ComentarioPublicoCardHeaderProps {
    index: number;
    elem: ItemDataSelect<ComentarioPublicoDetails>;
    mode: string;
    selectedEntitiesAction: SelectedDataActions<ComentarioPublicoDetails>;
}

export const ComentarioPublicoCardHeader = ({
    elem,
    index,
    selectedEntitiesAction,
    mode,
}: ComentarioPublicoCardHeaderProps) => {

    const comentario = elem.entity as ComentarioPublicoDetails;

    return (
        <HeaderStyle>
            <div className="card-header-left">
                <CheckBox
                    checked={selectedEntitiesAction.checkSelect(elem.id_item)}
                    onChange={(e) => {
                        selectedEntitiesAction.checkItem(index, e);
                    }}
                />
                <Tag nome={comentario.author_nickname} title="Apelido do usuário" />
                <Tag nome={comentario.publication_date} title="Data de publicação" />
            </div>
        </HeaderStyle>
    );
};
