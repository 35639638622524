import { CountTag } from "../../../../../components/page/structure/CountTag";
import { ComentarioPublicoDetails } from "../../../../../services/api-services/comentariosPublicos";
import { LabelValue } from "../../../../GerenciarComentarios/elements";
import { FooterRight, FooterStyle } from "../styles";

export interface ComentarioPublicoCardFooterProps {
    comentario: ComentarioPublicoDetails;
}

export const ComentarioPublicoCardFooter = ({ comentario }: ComentarioPublicoCardFooterProps) => {

    return (
        <FooterStyle className={"item-margin"}>
            <FooterRight>
                {comentario.updater !== null && (
                    <LabelValue
                        label={"Reeditado por"}
                        value={comentario.updater}
                    />
                )}
                <LabelValue
                    label={"Questão comentada(ID)"}
                    value={comentario.question_id as unknown as string}
                />
                <CountTag
                    count={comentario.likes as unknown as number}
                    title="Curtidas"
                    classname={"item-margin"}
                />
                <CountTag
                    count={comentario.dislikes as unknown as number}
                    title="Descurtidas"
                    classname={"item-margin"}
                />
            </FooterRight>
        </FooterStyle>
    );
};
