import styled from "styled-components";

export const ComentarioPublicoCardComponenteStyles = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: space-between;
  flex-wrap: wrap;
  margin-bottom: 12px;
  width: 100%;

  .card-header-left{
    display: flex;
    flex: 2 1 auto;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

 .card-header-right{
    margin-right: 18px;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    gap: 12px;
  }

  .item-margin {
    margin: 12px;
  }
`;

export const BodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 12px;

  .data-atualizacao {
    margin: 12px;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-text-grey);
  }

  .public_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 12px;
  }

  .tags {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

export const FooterStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 18px;
  background-color: var(--color-primary-clear);
  border-radius: 12px;

  .item-margin {
    margin: 12px;
  }
`;

export const FooterRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
`;

