import React, { Fragment, useEffect, useState } from "react";
import { MdViewHeadline } from "react-icons/md";
import { GroupButtons, TButtonSelect } from "../../components/group-buttons";
import { InputForm } from "../../components/input";
import { InputNormal } from "../../components/input/normal";
import { ErrorModal, SucessModal } from "../../components/modals/info-modal";
import { GerenciarPageBase } from "../../components/page/GerenciarPageBase";
import { useGerenciarContext } from "../../components/page/GerenciarPageBase/context";
import { GerenciarBaseProvider } from "../../components/page/GerenciarPageBase/provider";
import { useTagAction } from "../../components/page/structure/FilterComponent/hooks/useTagAction";
import { Group } from "../../components/page/structure/Group";
import { GroupMain } from "../../components/page/structure/Group/styles";
import {
  ItemDataSelect,
  useSelectedDataAction,
} from "../../components/page/structure/ListComponent/list-table/hooks/useSelectedDataAction";
import {
  comentarioPublicoOrdenacao
} from "../../mocks/data";
import { APIFetch } from "../../services/api";
import { ComentarioPublicoDetails, useComentarioPublicoService } from "../../services/api-services/comentariosPublicos";
import { useAuthContext } from "../../services/auth-services/auth/contextAuth";
import { EditarComentarioPublico } from "./forms/editar-comentario-publico";
import { ExcluirComentarioPublico } from "./forms/excluir-comentario-publico";
import { ComentarioPublicoCardRender } from "./views/card-questao/render";

export const ModerarComentariosPublicosComponent = () => {

  const auth = useAuthContext();

  const context = useGerenciarContext();

  const comentarioPublicoService = useComentarioPublicoService(APIFetch(auth));

  const [sort, setSort] = useState("");

  const [order, setOrder] = useState("desc");

  const [mode, setMode] = useState("item");

  const [period, setPeriod] = useState("");

  const [search, setSearch] = useState("");

  const [dataPublicacao, setDataPublicacao] = useState<string>("");

  const [qtdItems, setQtdItems] = useState("10");

  const comentariosState = useState<Array<ItemDataSelect<ComentarioPublicoDetails>>>([]);
  const setComentarios = comentariosState[1];

  const useTagActions = useTagAction();

  const selectedEntitiesAction = useSelectedDataAction<ComentarioPublicoDetails>({
    state: comentariosState,
  });

  const keys: string[] = [
    "nickname",
  ];

  useEffect(() => {
    if (context.loading) {

      comentarioPublicoService.listarComentariosPorPagina({
        filters: useTagActions.getFilters(keys),
        order: order,
        page: context.resetPagination() ? 0 : context.nextPage - 1,
        qtd: qtdItems,
        period: period,
        search: search,
        sort: sort,
        dataPublicacao: dataPublicacao
      }).then((data) => {
        setComentarios(
          data.content.map((value: ComentarioPublicoDetails) => {
            return {
              isChecked: false,
              id_item: value.public_comment_id,
              entity: { ...value },
            };
          })
        );

        context.changePaginationData(data.paginationData);
        context.toPage(data.paginationData.current_page);
        context.changeLoading(false);
        context.setIsNewPage(false);
      }).catch(() => {
        context.changeLoading(false);
      });

    }
  }, [context.loading]);

  const handleDateChange = (value: string) => {

    const [year, month, day] = value.split("-");

    const formatted = `${day}/${month}/${year}`;

    setDataPublicacao(formatted);

  };

  const filters = () => {
    return (
      <Fragment>
        <Group nameGroup="Filtros (Apelido, Data de publicação)" isOpen={true}>
          <GroupMain>
            <InputForm
              spellCheck={false}
              className="apelido field-group"
              name="apelido"
              onChange={(value) => {
                useTagActions.setTagValue(value.currentTarget.value, "nickname");
              }}
              placeholder="Apelido"
            />
            <InputNormal
              className="data-publicacao field-group"
              placeholder="Data publicação"
              type={"date"}
              onChangeValue={(value) => handleDateChange(value)}
            />
          </GroupMain>
        </Group>
      </Fragment>
    );
  };

  const loadingByButtonFilter = (
    current: TButtonSelect,
    setVar: React.SetStateAction<any>
  ) => {
    setVar(current.value);
    context.changeLoading(true);
  };

  const modals = () => {
    switch (context.currentAction.action) {

      case "reeditar-comentario": {
        return <EditarComentarioPublico />;
      }

      case "excluir": {
        return <ExcluirComentarioPublico />;
      }

      case "sucesso": {
        return (
          <SucessModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }
      case "erro": {
        return (
          <ErrorModal
            action={context.currentActionState}
            message={
              context.currentAction.message ? context.currentAction.message : ""
            }
            title={""}
            onOptionOk={() =>
              context.changeAction({
                action: "",
                id: 0,
              })
            }
          />
        );
      }

      default: {
        return <Fragment />;
      }
    }
  };

  return (
    <Fragment>
      <GerenciarPageBase<ComentarioPublicoDetails>
        callbacks={{
          search: (key) => {
            setSearch(key);

            if (key.length === 0) {
              context.changeLoading(true);
            }
          },
          setQtdItens: (qtd: string) => {
            setQtdItems(qtd);
            context.changeLoading(true);
          },
          onCurrentSortParam: (item) => {
            setSort(item);
            context.changeLoading(true);
          },
          onCurrentOrderParam: (sort) => {
            setOrder(sort);
            context.changeLoading(true);
          },
          setCurrentVisualizationMode: (button) => {
            setMode(button.value);
          },
        }}
        selectItensActions={selectedEntitiesAction}
        tagActions={useTagActions}
        dataState={comentariosState}
        filters={filters()}
        filterBar={{
          left: null,
          right: (
            <Fragment>
              <GroupButtons
                initialPositionSelected={3}
                marginRight="24px"
                buttons={[
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriod),
                    name: "hoje",
                    classname: "period-option",
                    value: "day",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriod),
                    name: "semana",
                    classname: "period-option",
                    value: "week",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriod),
                    name: "mês",
                    classname: "period-option",
                    value: "month",
                  },
                  {
                    onClick: (current) =>
                      loadingByButtonFilter(current, setPeriod),
                    classname: "period-option",
                    value: "",
                    minWidth: 9,
                    icon: MdViewHeadline,
                  },
                ]}
              />
            </Fragment>
          ),
        }}
        page={{
          name: "JusQuestões / Moderação de comentários",
          header: {
            left: <Fragment />,
            right: <Fragment />,
          },
          searchPlaceHolder: "Pesquisar por palavra-chave do conteúdo ou apenas o ID da questão",
          showFilters: true,
        }}
        visualization={{
          sort: {
            params: comentarioPublicoOrdenacao,
          },
          options: [],
          mode: "item",
          card: {
            dataColumn: ComentarioPublicoCardRender({ selectedEntitiesAction, mode }).component,
          }
        }}
      />
      {modals()}
    </Fragment>
  );
};


export const ModerarComentariosPublicos = () => {
  return (
    <GerenciarBaseProvider>
      <ModerarComentariosPublicosComponent />
    </GerenciarBaseProvider>
  );
};
